// imports
import EventDispatcher from '$dependencies/EventDispatcher';
import Request from './Request';

// private static properties
const DEFAULT_AXIOS_CFG = {
	baseUrl: '',
	url: '',
	timeout: 0,
	responseType: 'json',
	responseEncoding: 'utf8',
	xsrfHeaderName: 'X-CSRF-Token',
	params: () => ({}),
	data: () => ({}),
	transformResponse: () => [],
	headers: () => ({}),
};

const successStatusCodes = [200, 201, 202, 204];

// class definition
class Sync extends EventDispatcher {
	// constructor
	constructor(args) {
		super(args);

		// prepare properties & states
		this._config = {};
		this._loading = false;
		this._loadingMore = false;
		this._loadMethod = '';
		this._syncError = false;

		// options to allow reset of config without loosing constructor values
		this._options = args || {};

		// run some config methods
		this.resetConfig();

		// apply config parameter
		this.setConfig(args.config);
	}

	// methods
	initialize(args = {}) {
		super.initialize(args);
	}

	ajax(method = 'get', preventClear) {
		// unify method case
		method = method.toLowerCase();

		// prep axios with config values
		const request = new Request(method, this.config);

		// change state
		this.loadMethod = method;

		if (!preventClear) {
			this.loading = true;
		} else {
			this.loadingMore = true;
		}

		// emit events
		this.emit(method, {
			config: Object.assign({}, this.config),
			request,
		});

		// run axios & return promise
		return request
			.run()
			.then((xhr) => {
				// detect if error
				this._syncError = successStatusCodes.indexOf(xhr.status) === -1;

				// add successflag to xhr object
				xhr.success = !this._syncError;

				if (xhr.success && method) {
					// parse data while keeping hte original
					xhr.originalData = xhr.data;
					xhr.data = this.parse(xhr.data);

					// run response method
					this.response(xhr, preventClear);

					// reset properties
					this.loadMethod = '';
					this.loading = false;
					this.loadingMore = false;

					// emit response event
					this.emit('response', xhr);
				} else {
					this.error(xhr);

					// reset properties
					this.loadMethod = '';
					this.loading = false;

					// set sync status
					this.inSync = false;

					throw xhr;
				}
				return xhr;
			})
			.catch((error) => {
				this.error(error);
				throw error;
			});
	}

	parse(data) {
		return data;
	}

	error(error, skipProcessing = false) {
		if (!skipProcessing) {
			if (error.status) {
				error = {
					type: 'ajax',
					status: error.status,
					message: error.message || error.statusText,
					error,
					// xhr: error
				};
			} else {
				error = {
					type: 'ajax',
					error,
				};
			}
		}

		this.emit('error', error);
	}

	setConfig(prop, value) {
		if (typeof prop === 'object') {
			Object.assign(this._config, prop);
		} else if (value instanceof Blob) {
			// file upload
			this._config[prop] = value;
		} else if (typeof value === 'object') {
			Object.assign(this._config[prop], value);
		} else {
			this._config[prop] = value;
		}
		return this;
	}

	getConfig(prop, value) {
		return this._config[prop];
	}

	unsetConfig(prop, value = null) {
		if (value === null) {
			if (DEFAULT_AXIOS_CFG[prop]) {
				this._config[prop] = getDefaultConfig()[prop];
			} else {
				delete this._config[prop];
			}
		} else if (typeof value === 'object') {
			// remove each property separately
			for (const key of Object.keys(value)) {
				delete this._config[prop][key];
			}
		} else if (typeof value === 'string') {
			delete this._config[prop][value];
		}
		return this;
	}

	resetConfig() {
		this._config = Object.assign({}, getDefaultConfig(), this._options.config || {});
		return this;
	}

	response(response) {}

	destroy() {
		super.destroy();
	}

	// shortcuts
	fetch(preventClear) {
		return this.ajax('get', preventClear);
	}

	post() {
		return this.ajax('post');
	}

	patch() {
		return this.ajax('patch');
	}

	delete() {
		return this.ajax('delete');
	}

	put() {
		return this.ajax('put');
	}

	// getters & setters
	get loading() {
		return this._loading;
	}

	set loading(value) {
		if (this._loading !== value) {
			this._loading = value;
			this.emit(value ? 'loading' : 'loaded');
		}
	}

	get loadingMore() {
		return this._loadingMore;
	}

	set loadingMore(value) {
		if (this._loadingMore !== value) {
			this._loadingMore = value;
			this.emit(value ? 'loading' : 'loaded');
		}
	}

	get config() {
		return this._config;
	}

	get syncError() {
		return this._syncError;
	}
}

// prepares a new Default config object => running functions for instances to avoid shared pointers!
function getDefaultConfig() {
	const cfg = {};

	for (const key of Object.keys(DEFAULT_AXIOS_CFG)) {
		cfg[key] = typeof DEFAULT_AXIOS_CFG[key] === 'function' ? DEFAULT_AXIOS_CFG[key]() : DEFAULT_AXIOS_CFG[key];
	}

	return cfg;
}

// export
export default Sync;
