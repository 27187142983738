// imports
import _forIn from 'lodash/forIn';
import Model from '$dependencies/Model';
import PhotoCollection from '$collections/PhotoCollection';
import Config from '../../Config';

const FIELDS = {
	uuid: { type: String, default: '', identifier: true },
	title: { type: String, default: '' },
	created: { type: String, default: '' },
	color: { type: Object, default: () => {}},
	volume: { type: Number, default: 0 },
	material: { type: Object, default: () => {}},
	binId: { type: String, default: '' },
	customBinId: { type: String, default: '' },
	status: { type: Boolean, default: false },
	ashtray: { type: Boolean, default: false },
	postalCode: { type: String, default: '' },
	city: { type: String, default: '' },
	street: { type: String, default: '' },
	remark: { type: String, default: '' },
	lastMeasurement: { type: String, default: '' },
	firstMeasurement: { type: String, default: '' },
	locationType: { type: Object, default: () => {}},
	emptyingFrequency: { type: Object, default: () => {}},
	damageAmount: { type: Object, default: () => {}},
	location: { type: Object, default: {lat: null, lon: null}},
	type: { type: Object, default: () => {}},
	fraction: { type: Object, default: () => {}},
	requiredAction: {type: Object, default: () => {}},
	images: { type: Object, default: () => new PhotoCollection(), referenceType: 'file--file' },
	analysis: {type: Object, default: () => {}},
	fillDegreeCount: { type: Object, default: () => {}},
	lidSize: { type: Number, default: 0 },
	dumpCount: { type: Number, default: 0 },
	additionalDumpCount: { type: Number, default: 0 },
	measurementCount: { type: Number, default: 0 },
	incidentCount: { type: Number, default: 0 },
	lastIncidentDate: { type: String, default: '' },
	remarkCount: { type: Number, default: 0 },
	lastRemark: {type: Object, default: () => {}},
	surface: {type: Object, default: () => {}},
	allRemarks: {type: Array, default: []},
	bins: {type: Array, default: []},
	validityScore: { type: Number },
};

// class definition
export default class BinModel extends Model {
	// constructor
	constructor(args = {}) {
		// clnge args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	fetch() {
		this.setConfig('url', `${Config.getUrl('c_bins')}/${this.uuid}`);
		return super.fetch();
	}

	patch(data, options = {}) {
		this.setConfig('url', `${Config.getUrl('c_bins')}`)
		return super.patch(prepareDataForPatch(cleanUpPatchFields(data || this.toObject(true), options.fieldsToPatch)))
	}

	parse(data) {
		if (!data) return

		if(data.data) {
			data.data.images.map(image => {
				return Object.assign(image, {id: image.uuid});
			});
			return super.parse(data.data);
		}

		if(data.images) {
			data.images.map(image => {
				return Object.assign(image, {id: image.uuid});
			});
		}

		return super.parse(data);
	}

	get id() {
		return this.uuid;
	}


	get analyseData() {
		const analyseSets = [];

		_forIn(this.analysis, (value, key) => {
			if(key !== 'count') {
				analyseSets.push(createAnalyseSet(key, value, this.analysis.count));
			}
		});

		return analyseSets;
	}

	get fillDegreeData() {
		const modifiers = [0,50,100,125];
		const analyseSet = createAnalyseSet('fillDegree', this.fillDegreeCount, this.measurementCount, Config.garbageBin.fillDegreeLabels);
		for (let i = 0; i < analyseSet.data.length; i++) {
			analyseSet.data[i]['modifier'] = modifiers[i];
		}
		return analyseSet;
	}

	get active() {
		return this.status;
	}

	// extra getters
	get id() {
		return this.uuid;
	}

	get fullAddress() {
		let full = '';

		if (this.street) full += this.street;
		if (this.street && (this.postalCode.postalCode || this.city)) full += ', ';
		if (this.postalCode.postalCode) full += this.postalCode.postalCode;
		if (this.city) full += ` ${this.city}`;

		return full;
	}


	get coordinates() {
		return { latitude: this.location.lat, longitude: this.location.lon };
	}

	get gmapCoordinates() {
		return { lat: this.location.lat, lng: this.location.lon };
	}

	get latitude() {
		return this.location.lat;
	}

	get longitude() {
		return this.location.lon;
	}

	set latitude(val) {
		return(this.location.lat = val);
	}

	set longitude(val) {
		return(this.location.lon = val);
	}
}

function createAnalyseSet(key, value, total, dictionary = {}) {
	const set = {
		title: key,
		data: []
	};

	_forIn(value, (count, label) => {
		set.data.push({
			label: dictionary[label] ? dictionary[label] : label,
			percentage: count
		});
	});

	set.data.map(ds => {
		ds.percentage = ds.percentage > 0 ? Math.round((100 / total) * ds.percentage) : 0;
	});

	return set;
}

function prepareDataForPatch(data) {
	if (data.emptyingFrequency) {
		data.emptyingFrequency = data.emptyingFrequency.id
	}

	return data
}

function cleanUpPatchFields(data, fieldsToPatch) {
	if (!fieldsToPatch) return data;
	if (data && fieldsToPatch) {
		for (const field in data) {
			if (!fieldsToPatch.includes(field)) {
				delete data[field];
			}
		}
	}
	return data;
}
