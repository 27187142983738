import Config from "$root/Config";

import Login from "$pages/Login/Login.vue";

const PasswordRequest = () => import( /* webpackChunkName: "password-request" */ "$pages/PasswordRequest/PasswordRequest.vue" );
const PasswordReset = () => import( /* webpackChunkName: "password-reset" */ "$pages/PasswordReset/PasswordReset.vue" );

const Home = () => import( /* webpackChunkName: "home" */ "$pages/Home/Home.vue" );

const Reporting = () => import( /* webpackChunkName: "reporting" */ "$pages/Reporting/Reporting.vue" );
const ReportingIncidents = () => import( /* webpackChunkName: "reporting-incidents" */ "$pages/ReportingIncidents/ReportingIncidents.vue" );
const ReportingBins = () => import( /* webpackChunkName: "reporting-bins" */ "$pages/ReportingBins/ReportingBins.vue" );
const ReportingMonitoring = () => import( /* webpackChunkName: "reporting-monitoring" */ "$pages/ReportingMonitoring/ReportingMonitoring.vue" );

const HeatmapTest = () => import( /* webpackChunkName: "HeatmapTest" */ "$pages/HeatmapTest/HeatmapTest.vue" );
const HeatmapTest2 = () => import( /* webpackChunkName: "HeatmapTest2" */ "$pages/HeatmapTest2/HeatmapTest2.vue" );
const GpxTest = () => import( /* webpackChunkName: "HeatmapTest2" */ "$pages/GpxTest/GpxTest.vue" );
const GpxTestAll = () => import( /* webpackChunkName: "HeatmapTest2" */ "$pages/GpxTestAll/GpxTestAll.vue" );
const GpxTestAllCompressed = () => import( /* webpackChunkName: "HeatmapTest2" */ "$pages/GpxTestAllCompressed/GpxTestAllCompressed.vue" );
const ActivityMapDetail = () => import ( /* webpackChunkName: "ActivityMapDetail" */ "$pages/PDFs/ActivityMapDetail/ActivityMapDetail.vue" );

const IncidentDetail = () => import( /* webpackChunkName: "incident" */ "$pages/IncidentDetail/IncidentDetail.vue" );
const Incidents = () => import( /* webpackChunkName: "incidents" */ "$pages/Incidents/Incidents.vue" );
const IncidentsMap = () => import( /* webpackChunkName: "incidents-map" */ "$pages/IncidentsMap/IncidentsMap.vue" );
const IncidentCreation = () => import( /* webpackChunkName: "incident-creation" */ "$pages/IncidentCreation/IncidentCreation.vue" );
const IncidentRegistrationStart = () => import( /* webpackChunkName: "incident-registration-start" */ "$pages/IncidentRegistrationStart/IncidentRegistrationStart.vue" );
const IncidentRegistration = () => import( /* webpackChunkName: "incident-registration" */ "$pages/IncidentRegistration/IncidentRegistration.vue" );
const IncidentRegistrationEnd = () => import( /* webpackChunkName: "incident-registration-end" */ "$pages/IncidentRegistrationEnd/IncidentRegistrationEnd.vue" );
const IncidentEnd = () => import( /* webpackChunkName: "incident-end" */ "$pages/IncidentEnd/IncidentEnd.vue" );
const IncidentDuplicate = () => import( /* webpackChunkName: "incident-duplicate" */ "$pages/IncidentDuplicate/IncidentDuplicate.vue" );
const IncidentsCharts = () => import( /* webpackChunkName: "measurement-detail" */ "$pages/IncidentsCharts/IncidentsCharts.vue" );

const GarbageBinDetail = () => import( /* webpackChunkName: "garbage-bin-detail" */ "$pages/GarbageBinDetail/GarbageBinDetail.vue" );
const GarbageBinInventory = () => import( /* webpackChunkName: "garbage-bin-overview" */ "$pages/GarbageBinInventory/GarbageBinInventory.vue" );
const GarbageBinMapOverview = () => import( /* webpackChunkName: "garbage-bin-map-overview" */ "$pages/GarbageBinMapOverview/GarbageBinMapOverview.vue" );
const GarbageBinCreation = () => import( /* webpackChunkName: "garbage-bin-creation" */ "$pages/GarbageBinCreation/GarbageBinCreation.vue" );
const GarbageBinCreationEnd = () => import( /* webpackChunkName: "garbage-bin-creation-end" */ "$pages/GarbageBinCreationEnd/GarbageBinCreationEnd.vue" );

const GarbageBinMeasurement = () => import( /* webpackChunkName: "garbage-bin-measurement" */ "$pages/GarbageBinMeasurement/GarbageBinMeasurement.vue" );
const GarbageBinMeasurementEnd = () => import( /* webpackChunkName: "garbage-bin-measurement-end" */ "$pages/GarbageBinMeasurementEnd/GarbageBinMeasurementEnd.vue" );
const GarbageBinMeasurementOverview = () => import( /* webpackChunkName: "garbage-bin-measurement-overview" */ "$pages/GarbageBinMeasurementOverview/GarbageBinMeasurementOverview.vue" );
const MeasurementDetail = () => import( /* webpackChunkName: "measurement-detail" */ "$pages/MeasurementDetail/MeasurementDetail.vue" );
const MeasurementsCharts = () => import( /* webpackChunkName: "measurement-detail" */ "$pages/MeasurementsCharts/MeasurementsCharts.vue" );

const VolunteersActive = () => import( /* webpackChunkName: "measurement-detail" */ "$pages/VolunteersActive/VolunteersActive.vue" );
const Activities = () => import( /* webpackChunkName: "measurement-detail" */ "$pages/Activities/Activities.vue" );
const VolunteerDetail = () => import( /* webpackChunkName: "incident" */ "$pages/VolunteerDetail/VolunteerDetail.vue" );
const VolunteerMaps = () => import( /* webpackChunkName: "volunteer-map" */ "$pages/VolunteerMaps/VolunteerMaps.vue" );
const ActivityDetail = () => import( /* webpackChunkName: "incident" */ "$pages/ActivityDetail/ActivityDetail.vue" );

const ProjectCreation = () => import( /* webpackChunkName: "project-creation" */ "$pages/ProjectCreation/ProjectCreation.vue" );
const ProjectCreationEnd = () => import( /* webpackChunkName: "project-creation-end" */ "$pages/ProjectCreationEnd/ProjectCreationEnd.vue" );
const ProjectDetail = () => import( /* webpackChunkName: "monitoring" */ "$pages/ProjectDetail/ProjectDetail.vue" );
const ProjectsOverview = () => import( /* webpackChunkName: "monitoring" */ "$pages/ProjectsOverview/ProjectsOverview.vue" );
const ProjectsMap = () => import( /* webpackChunkName: "monitoring" */ "$pages/ProjectsMap/ProjectsMap.vue" );
const LocationWizard = () => import( /* webpackChunkName: "monitoring" */ "$pages/LocationWizard/LocationWizard.vue" );

const MeasurePreview = () => import( /* webpackChunkName: "incident" */ "$pages/MeasurePreview/MeasurePreview.vue" );

const ProjectMeasurement = () => import( /* webpackChunkName: "measurement-creation" */ "$pages/ProjectMeasurement/ProjectMeasurement.vue" );
const ProjectMeasurementEnd = () => import( /* webpackChunkName: "measurement-creation" */ "$pages/ProjectMeasurementEnd/ProjectMeasurementEnd.vue" );
const ProjectMeasurementDetail = () => import( /* webpackChunkName: "project-measurement-detail" */ "$pages/ProjectMeasurementDetail/ProjectMeasurementDetail.vue" );

const ProjectExperienceScan = () => import( /* webpackChunkName: "experience-scan-creation" */ "$pages/ProjectExperienceScan/ProjectExperienceScan.vue" );
const ProjectExperienceScanEnd = () => import( /* webpackChunkName: "experience-scan-creation-end" */ "$pages/ProjectExperienceScanEnd/ProjectExperienceScanEnd.vue" );
const ExperienceScanDetail = () => import( /* webpackChunkName: "experience-scan-detail" */ "$pages/ExperienceScanDetail/ExperienceScanDetail.vue" );

const NotFound = () => import( /* webpackChunkName: "not-found" */ "$pages/NotFound/NotFound.vue" );

const CountingForm = () => import(/* webpackChunkName: "pdfs" */ '$pages/PDFs/CountingForm/CountingForm.vue');
const ProjectExportPDF = () => import(/* webpackChunkName: "pdfs" */ '$pages/PDFs/ProjectExport/ProjectExport.vue');
const MeasureExportPDF = () => import(/* webpackChunkName: "pdfs" */ '$pages/PDFs/MeasureExport/MeasureExport.vue');
const ExperienceScansExportPDF = () => import(/* webpackChunkName: "pdfs" */ '$pages/PDFs/ExperienceScansExport/ExperienceScansExport.vue');
const ProjectComparisonExportPDF = () => import(/* webpackChunkName: "pdfs" */ '$pages/PDFs/ProjectComparisonExport/ProjectComparisonExport.vue');
const BinGroupDetailExportPDF = () => import(/* webpackChunkName: "pdfs" */ '$pages/PDFs/BinGroupDetailExport/BinGroupDetailExport.vue');
const EventsOverviewExportPDF = () => import(/* webpackChunkName: "pdfs" */ '$pages/PDFs/EventsOverviewExport/EventsOverviewExport.vue');
const EventsUserOverviewExportPDF = () => import(/* webpackChunkName: "pdfs" */ '$pages/PDFs/EventsUserOverviewExport/EventsUserOverviewExport.vue');
const GroupsOverviewExportPDF = () => import(/* webpackChunkName: "pdfs" */ '$pages/PDFs/GroupsOverviewExport/GroupsOverviewExport.vue');
const UserGroupsOverviewExportPDF = () => import(/* webpackChunkName: "pdfs" */ '$pages/PDFs/UserGroupsOverviewExport/UserGroupsOverviewExport.vue');
const BinMeasurementComparisonExport = () => import(/* webpackChunkName: "pdfs" */ '$pages/PDFs/BinMeasurementComparisonExport/BinMeasurementComparisonExport.vue');

const ExportCreation = () => import( /* webpackChunkName: "export-creation" */ "$pages/ExportCreation/ExportCreation.vue" );
const ExportCreationEnd = () => import( /* webpackChunkName: "export-creation-end" */ "$pages/ExportCreationEnd/ExportCreationEnd.vue" );

const MonitoringReport = () => import( /* webpackChunkName: "monitoring-report" */ "$pages/MonitoringReport/MonitoringReport.vue" );
const ProjectComparison = () => import( /* webpackChunkName: "project-comparison" */ "$pages/ProjectComparison/ProjectComparison.vue" );

const BinGroupWizard = () => import( /* webpackChunkName: "bin-groups" */ "$pages/BinGroupWizard/BinGroupWizard.vue" );
const BinGroupEnd = () => import( /* webpackChunkName: "bin-groups" */ "$pages/BinGroupEnd/BinGroupEnd.vue" );
const BinGroupDetail = () => import( /* webpackChunkName: "bin-groups-detail" */ "$pages/BinGroupDetail/BinGroupDetail.vue" );

const MeasuringPeriodWizard = () => import( /* webpackChunkName: "measuring-period" */ "$pages/MeasuringPeriodWizard/MeasuringPeriodWizard.vue" );
const MeasuringPeriodEnd = () => import( /* webpackChunkName: "measuring-period" */ "$pages/MeasuringPeriodEnd/MeasuringPeriodEnd.vue" );
const MeasuringPeriodDetail = () => import( /* webpackChunkName: "measuring-period-detail" */ "$pages/MeasuringPeriodDetail/MeasuringPeriodDetail.vue" );

const EventOverview = () => import(/* webpackChunkName: "cleaning-action-overview" */ '$pages/EventsOverview/EventsOverview.vue');
const EventDetail = () => import(/* webpackChunkName: "cleaning-action-detail" */ '$pages/EventDetail/EventDetail.vue');
const GroupsOverview = () => import(/* webpackChunkName: "volunteer-groups-overview" */ '$pages/GroupsOverview/GroupsOverview.vue');
const GroupsDetail = () => import(/* webpackChunkName: "volunteer-groups-detail" */ '$pages/GroupsDetail/GroupsDetail.vue');
const VolunteerPostDetail = () => import(/* webpackChunkName: "volunteer-post-detail" */ '$pages/VolunteerPostDetail/VolunteerPostDetail.vue');
const VolunteerPostWizard = () => import(/* webpackChunkName: "volunteer-post-wizard" */ '$pages/VolunteerPostWizard/VolunteerPostWizard.vue');

export default {
	routes: [
		{
			name: "Login",
			path: Config.routes.login,
			component: Login,
			meta: {
				title: "Mijn Mooie Straat - Mooimakers",
				hideFor: true,
			},
			props: {
				page: {
					classList: ["login"],
				},
			},
		},

		{
			name: "Reset password",
			path: Config.routes.password.reset,
			component: PasswordRequest,
			meta: {
				hideForAuth: true,
			},
			props: {
				page: {
					classList: ["passwordRequest"],
				},
			},
		},

		{
			name: "Change password",
			path: Config.routes.password.change,
			component: PasswordReset,
			meta: {
				hideForAuth: true,
			},
			props: {
				page: {
					classList: ["passwordReset"],
				},
			},
		},

		{
			name: "Home",
			path: Config.routes.home,
			component: Home,
			meta: {
				title: "Start - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["menu"],
				},
			},
		},

		{
			name: "Rapportage Dashboard",
			path: Config.routes.reporting.home,
			component: Reporting,
			meta: {
				title: "Rapportage Dashboard - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["menu"],
				},
			},
		},
		{
			name: "Rapportage Dashboard - Meldingen",
			path: Config.routes.reporting.incidents,
			component: ReportingIncidents,
			meta: {
				title: "Rapportage Dashboard - Meldingen - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["reportingIncidents"],
				},
			},
		},
		{
			name: "Rapportage Dashboard - Vuilnisbakken",
			path: Config.routes.reporting.bins,
			component: ReportingBins,
			meta: {
				title: "Rapportage Dashboard - Vuilnisbakken - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["reportingBins"],
				},
			},
		},
		{
			name: "Rapportage Dashboard - Projecten",
			path: Config.routes.reporting.monitoring,
			component: ReportingMonitoring,
			meta: {
				title: "Rapportage Dashboard - Projecten - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["reportingMonitoring"],
				},
			},
		},

		{
			name: "Heatmaptest",
			path: "/heatmapold",
			component: HeatmapTest,
			meta: {
				title: "Start - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["menu"],
				},
			},
		},

		{
			name: "Heatmaptest",
			path: "/heatmap2",
			component: HeatmapTest2,
			meta: {
				title: "Start - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["menu"],
				},
			},
		},

		{
			name: "GpxTest",
			path: "/heatmap",
			component: GpxTest,
			meta: {
				title: "Start - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["menu"],
				},
			},
		},

		{
			name: "ActivityMapDetail",
			path: `${Config.routes.volunteers.activityMapDetail}:id`,
			component: ActivityMapDetail,
			meta: {
				title: "Opruimsessie Map Detail",
			},
		},

		{
			name: "GpxTestAll",
			path: "/gpxall",
			component: GpxTestAll,
			meta: {
				title: "Start - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["menu"],
				},
			},
		},

		{
			name: "GpxTestAllCompressed",
			path: "/gpxallCompressed",
			component: GpxTestAllCompressed,
			meta: {
				title: "Start - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["menu"],
				},
			},
		},

		{
			name: "Incidents",
			path: Config.routes.incidents.overview,
			component: Incidents,
			meta: {
				title: "Melding overzicht - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["incidents"],
				},
			},
		},

		{
			name: "Incidents Map",
			path: Config.routes.incidents.map,
			component: IncidentsMap,
			meta: {
				title: "Kaartoverzicht meldingen - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["incidents"],
				},
			},
		},

		{
			name: "Incidents Rapportage",
			path: Config.routes.incidents.charts,
			component: IncidentsCharts,
			meta: {
				title: "Rapportage meldingen - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["incidentsCharts"],
				},
			},
		},

		{
			name: "Incidents Add",
			path: Config.routes.incidents.new,
			component: IncidentCreation,
			meta: {
				title: "Melding maken - Mijn Mooie Straat",
				excludeFromHistory: true,
			},
			props: ({ params: { fromEnd } }) => ({
				page: {
					classList: ["incidentCreation"],
				},
				fromEnd,
			}),
		},

		{
			name: "Incidents end",
			path: Config.routes.incidents.end,
			component: IncidentEnd,
			meta: {
				title: "Melding aangemaakt - Mijn Mooie Straat",
				excludeFromHistory: true,
			},
			props: {
				page: {
					classList: ["incidentCreationThanks"],
				},
			},
		},

		{
			name: "Incidents duplicate",
			path: Config.routes.incidents.duplicate,
			meta: {
				excludeFromHistory: true,
			},
			component: IncidentDuplicate,
			props: {
				page: {
					classList: ["incidentCreationThanks"],
				},
			},
		},

		{
			name: "Incidents detail",
			path: `${Config.routes.incidents.detail}:id`,
			component: IncidentDetail,
			meta: {
				title: "Melding detail - Mijn Mooie Straat",
			},
			props: ({ params: { backUrl } }) => ({
				page: {
					classList: ["incident"],
				},
				backUrl,
			}),
		},

		{
			name: "Burgermelding start",
			path: Config.routes.incidents.registrationStart,
			component: IncidentRegistrationStart,
			meta: {
				title: "Burgermelding start - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["incidentRegistration"],
				},
			},
		},

		{
			name: "Burgermelding form",
			path: Config.routes.incidents.registrationForm,
			component: IncidentRegistration,
			meta: {
				title: "Burgermelding form",
			},
			props: ({ params: { routeData, communityId } }) => ({
				page: {
					classList: ["incidentCreation registration"],
				},
				routeData,
				communityId,
			}),
		},

		{
			name: "Burgermelding end",
			path: Config.routes.incidents.registrationEnd,
			component: IncidentRegistrationEnd,
			meta: {
				title: "Burgermelding end",
				excludeFromHistory: true,
			},
			props: ({ params: { routeData } }) => ({
				page: {
					classList: ["incidentRegistration"],
				},
				routeData,
			}),
			beforeEnter: (to, from, next) => {
				if(!from.params.communityId) {
					console.error(`Incident registration end empty parameters from ${from.fullPath}`)
				} else {
					next();
				}
			},
		},

		{
			name: "Garbage bin overview",
			path: Config.routes.bins.overview,
			component: GarbageBinInventory,
			meta: {
				title: "Inventaris - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["garbageBinOverview"],
				},
			},
		},

		{
			name: "Garbage bin map overview",
			path: Config.routes.bins.mapOverview,
			component: GarbageBinMapOverview,
			meta: {
				title: "Kaartoverzicht afvalpunten - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["garbageBinMapOverview"],
				},
			},
		},

		{
			name: "garbageBinDetail",
			path: `${Config.routes.bins.detail}:id`,
			component: GarbageBinDetail,
			meta: {
				title: "Afvalpunt detail - Mijn Mooie Straat",
			},
			props: ({ query: { backUrl } }) => ({
				page: {
					classList: ["garbageBinDetail"],
				},
				backUrl,
			}),
		},

		{
			name: "Garbage bin creation",
			path: Config.routes.bins.new,
			component: GarbageBinCreation,
			meta: {
				title: "Afvalpunt toevoegen - Mijn Mooie Straat",
			},
			props: ({ params: { fromEnd } }) => ({
				page: {
					classList: ["garbageBinCreation"],
				},
				fromEnd,
			}),
		},

		{
			name: "Garbage bin end",
			path: Config.routes.bins.end,
			component: GarbageBinCreationEnd,
			meta: {
				title: "Afvalpunt toegevoegd - Mijn Mooie Straat",
				excludeFromHistory: true,
			},
			props: {
				page: {
					classList: ["garbageBinCreationEnd"],
				},
			},
		},

		{
			name: "Garbage bin add measurement",
			path: Config.routes.measurements.new,
			component: GarbageBinMeasurement,
			meta: {
				title: "Meting aanmaken - Mijn Mooie Straat",
				excludeFromHistory: true,
			},
			props: ({ params: { fromEnd } }) => ({
				page: {
					classList: ["garbageBinAddMeasurement"],
				},
				fromEnd,
			}),
		},

		{
			name: "Garbage bin measurement end",
			path: Config.routes.measurements.end,
			component: GarbageBinMeasurementEnd,
			meta: {
				title: "Meting aangemaakt - Mijn Mooie Straat",
				excludeFromHistory: true,
			},
			props: {
				page: {
					classList: ["garbageBinMeasurementEnd"],
				},
			},
		},

		{
			name: "Garbage bin measurement overview",
			path: Config.routes.measurements.overview,
			component: GarbageBinMeasurementOverview,
			meta: {
				title: "Meting overzicht - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["garbageBinMeasurementsOverview"],
				},
			},
		},

		{
			name: "Garbage bin measurement detail",
			path: `${Config.routes.measurements.detail}:id`,
			component: MeasurementDetail,
			meta: {
				title: "Meting detail - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["measurementDetail"],
				},
			},
		},

		{
			name: "Measurements Charts",
			path: Config.routes.measurements.charts,
			component: MeasurementsCharts,
			meta: {
				title: "Rapportage metingen - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["measurementsCharts"],
				},
			},
		},

		{
			name: "Active volunteers",
			path: Config.routes.volunteers.active,
			component: VolunteersActive, // TODO: Add valid page
			meta: {
				title: "Actieve vrijwilligers",
			},
			props: {
				page: {
					classList: ["volunteersActive"],
				},
			},
		},

		{
			name: "Volunteer detail",
			path: `${Config.routes.volunteers.detail}:id`,
			component: VolunteerDetail,
			meta: {
				title: "Actieve vrijwiligger - Mijn Mooie Straat",
			},
			props: ({ params: { backUrl } }) => ({
				page: {
					classList: ["volunteer"],
				},
				backUrl,
			}),
		},
		{
			name: "Activities",
			path: Config.routes.volunteers.activities,
			component: Activities,
			meta: {
				title: "Opruimsessies",
			},
			props: {
				page: {
					classList: ["activities"],
				},
			},
		},

		{
			name: "activityDetail",
			path: `${Config.routes.volunteers.activity}:id`,
			component: ActivityDetail,
			meta: {
				title: "Opruimsessie",
			},
			props: ({ params: { backUrl } }) => ({
				page: {
					classList: ["activity"],
				},
				backUrl,
			}),
		},

		{
			name: "Vrijwilligers Map",
			path: Config.routes.volunteers.map,
			component: VolunteerMaps,
			meta: {
				title: "Kaart & Heatmap",
			},
			props: {
				page: {
					classList: ["volunteersMap"],
				},
			},
		},

		{
			name: "Map",
			path: Config.routes.volunteers.charts,
			component: Home, // TODO: Add valid page
			meta: {
				title: "Rapportage",
			},
			props: {
				page: {
					classList: ["volunteersCharts"],
				},
			},
		},

		{
			name: "Settings",
			path: Config.routes.volunteers.settings,
			component: Home, // TODO: Add valid page
			meta: {
				title: "Instellingen",
			},
			props: {
				page: {
					classList: ["volunteersMap"],
				},
			},
		},

		{
			name: "ProjectCreation",
			path: Config.routes.projects.new,
			component: ProjectCreation, // TODO: Add valid page
			meta: {
				title: "Nieuw project aanmaken",
			},
			props: {
				page: {
					classList: ["projectCreation"],
				},
			},
		},

		{
			name: "ProjectCreationEnd",
			path: Config.routes.projects.end,
			component: ProjectCreationEnd, // TODO: Add valid page
			meta: {
				title: "Project aangemaakt",
				excludeFromHistory: true,
			},
			props: {
				page: {
					classList: ["projectCreationEnd"],
				},
			},
		},

		{
			name: "ProjectDetail",
			path: `${Config.routes.projects.detail}:id`,
			component: ProjectDetail, // TODO: Add valid page
			meta: {
				title: "Projectdetail",
			},
			props: {
				page: {
					classList: ["projectDetail"],
				},
			},
		},

		{
			name: "ProjectsOverview",
			path: Config.routes.projects.overview,
			component: ProjectsOverview, // TODO: Add valid page
			meta: {
				title: "Projecten",
			},
			props: {
				page: {
					classList: ["projectsOverview"],
				},
			},
		},

		{
			name: "projectLocationWizard",
			path: Config.routes.projects.locationWizard,
			component: LocationWizard, // TODO: Add valid page
			meta: {
				title: "Locatie aanpassen",
			},
			props: ({ params: { project } }) => ({
				page: {
					classList: ["projectCreation"],
				},
				project,
			}),
		},

		{
			name: "ProjectMeasurementDetail",
			path: `${Config.routes.projectMeasurements.detail}:id`,
			component: ProjectMeasurementDetail, // TODO: Add valid page
			meta: {
				title: "Metingdetail",
			},
			props: {
				page: {
					classList: ["projectMeasurementDetail"],
				},
			},
		},

		{
			name: "404",
			path: "*",
			component: NotFound,
			props: {
				page: {
					classList: ["notFound"],
				},
			},
		},
		{
			name: "MeasurePreview",
			path: `${Config.routes.measure.preview}:id`,
			component: MeasurePreview,
			meta: {
				title: "Measure preview",
			},
			props: {
				page: {
					classList: ["measurePreview"],
				},
			},
		},

		{
			name: "ProjectMeasurement",
			path: Config.routes.projectMeasurements.new,
			component: ProjectMeasurement, // TODO: Add valid page
			meta: {
				title: "Nieuwe meting aanmaken",
			},
			props: {
				page: {
					classList: ["projectCreation"],
				},
			},
		},

		{
			name: "ProjectMeasurementEnd",
			path: Config.routes.projectMeasurements.end,
			component: ProjectMeasurementEnd, // TODO: Add valid page
			meta: {
				title: "Meting aangemaakt",
				excludeFromHistory: true,
			},
			props: ({ params: { model } }) => ({
				page: {
					classList: ["projectMeasurementEnd"],
				},
				model,
			}),
		},

		{
            name: 'ProjectExperienceScan',
            path: Config.routes.projectExperienceScans.new,
            component: ProjectExperienceScan,
            meta: {
                title: 'Nieuwe belevingsscan aanmaken',
            },
            props: {
                page: {
                    classList: ['projectExperience'],
                },
            },
        },
        {
            name: 'ProjectExperienceScanEnd',
            path: Config.routes.projectExperienceScans.end,
            component: ProjectExperienceScanEnd,
            meta: {
                title: 'Belevingsscan aangemaakt',
                excludeFromHistory: true,
            },
            props: ({ params: { model } }) => ({
                page: {
                    classList: ['projectExperienceScanEnd'],
                },
                model,
            }),
        },
        {
            name: 'ExperienceScanDetail',
            path: `${Config.routes.projectExperienceScans.detail}:id`,
            component: ExperienceScanDetail,
            meta: {
                title: 'Belevingsscan detail',
            },
            props: ({ params: { backUrl } }) => ({
                page: {
                    classList: ['experienceScanDetail'],
                },
                backUrl,
            }),
        },
        {
            name: 'CountingForm',
            path: `${Config.routes.pdfs.countingForm}:id`,
            component: CountingForm,
            props: {
                page: {
                    classList: ['CountingForm'],
                },
            },
        },
        {
            name: 'ProjectExportPDF',
            path: `${Config.routes.pdfs.project}:id`,
            component: ProjectExportPDF,
            props: {
                page: {
                    classList: ['ProjectExportPDF'],
                },
            },
        },

        {
            name: 'ExportCreationEnd',
            path: `${Config.routes.reporting.exportsEnd}`,
            component: ExportCreationEnd,
            props: {
                page: {
                    classList: ['exportCreationEnd'],
                },
            },
        },
        {
            name: 'ExperienceScansExportPDF',
            path: `${Config.routes.pdfs.experienceScansExport}:id`,
            component: ExperienceScansExportPDF,
            props: {
                page: {
                    classList: ['ExperienceScansExportPDF'],
                },
            },
        },
        {
            name: 'ProjectComparisonExportPDF',
            path: `${Config.routes.pdfs.projectComparisonExport}`,
            component: ProjectComparisonExportPDF,
            props: {
                page: {
                    classList: ['ProjectComparisonExportPDF'],
                },
            },
        },
        {
            name: 'BinGroupDetailExportPDF',
            path: `${Config.routes.pdfs.binGroupDetailExport}:id`,
            component: BinGroupDetailExportPDF,
            props: {
                page: {
                    classList: ['BinGroupDetailExportPDF'],
                },
            },
        },
        {
            name: 'EventsOverviewExportPDF',
            path: `${Config.routes.pdfs.eventsOverviewExport}`,
            component: EventsOverviewExportPDF,
            props: {
                page: {
                    classList: ['EventsOverviewExportPDF'],
                },
            },
        },
        {
            name: 'EventsUserOverviewExportPDF',
            path: `${Config.routes.pdfs.eventsUserOverviewExport}`,
            component: EventsUserOverviewExportPDF,
            props: {
                page: {
                    classList: ['EventsUserOverviewExportPDF'],
                },
            },
        },
        {
            name: 'GroupsOverviewExportPDF',
            path: `${Config.routes.pdfs.groupsOverviewExport}`,
            component: GroupsOverviewExportPDF,
            props: {
                page: {
                    classList: ['GroupsOverviewExportPDF'],
                },
            },
        },
        {
            name: 'UserGroupsOverviewExportPDF',
            path: `${Config.routes.pdfs.userGroupsOverviewExport}`,
            component: UserGroupsOverviewExportPDF,
            props: {
                page: {
                    classList: ['UserGroupsOverviewExportPDF'],
                },
            },
        },

        {
            name: 'BinMeasurementComparisonExport',
            path: `${Config.routes.pdfs.binMeasurementComparison}`,
            component: BinMeasurementComparisonExport,
            props: {
                page: {
                    classList: ['BinMeasurementComparisonExport'],
                },
            },
        },

		{
			name: "ProjectsMap",
			path: Config.routes.projects.map,
			component: ProjectsMap, // TODO: Add valid page
			meta: {
				title: "Projecten overzicht",
			},
			props: {
				page: {
					classList: ["projectsMap"],
				},
			},
		},

		{
			name: "MeasureExportPDF",
			path: `${Config.routes.pdfs.measureExport}:id`,
			component: MeasureExportPDF,
			props: {
				page: {
					classList: ["MeasureExportPDF"],
				},
			},
		},

		{
			name: "ExportCreation",
			path: `${Config.routes.reporting.exports}`,
			component: ExportCreation,
			props: {
				page: {
					classList: ["exportCreation"],
				},
			},
		},

		{
			name: "Rapportage Monitoring",
			path: Config.routes.monitoring.reports,
			component: MonitoringReport,
			meta: {
				title: "Rapportage monitoring - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["MonitoringReport"],
				},
			},
		},
		{
			name: "Project vergelijking",
			path: Config.routes.monitoring.comparison,
			component: ProjectComparison,
			meta: {
				title: "Project vergelijking - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["ProjectComparison"],
				},
			},
		},

		{
			name: "Groep wizard",
			path: Config.routes.bins.groupWizard,
			component: BinGroupWizard,
			meta: {
				title: "Groep aanmaken - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["binGroupWizard"],
				},
			},
		},
		{
			name: "BinGroupEnd",
			path: Config.routes.bins.groupEnd,
			component: BinGroupEnd,
			meta: {
				title: "Groep aangemaakt",
				excludeFromHistory: true,
			},
			props: ({ params: { model } }) => ({
				page: {
					classList: ["binGroupEnd"],
				},
				model,
			}),
		},

		{
			name: "BinGroupDetail",
			path: `${Config.routes.bins.groupDetail}:id`,
			component: BinGroupDetail,
			meta: {
				title: "Groep Detail",
			},
			props: ({ params: backUrl }) => ({
				page: {
					classList: ["binGroupDetail"],
				},
				backUrl,
			}),
		},

		{
			name: "Meetperiode wizard",
			path: Config.routes.bins.measuringPeriodWizard,
			component: MeasuringPeriodWizard,
			meta: {
				title: "Meetperiode aanmaken - Mijn Mooie Straat",
			},
			props: {
				page: {
					classList: ["measuringPeriodWizard"],
				},
			},
		},
		{
			name: "MeasuringPeriodEnd",
			path: Config.routes.bins.measuringPeriodEnd,
			component: MeasuringPeriodEnd,
			meta: {
				title: "Meetperiode aangemaakt",
				excludeFromHistory: true,
			},
			props: ({ params: { model } }) => ({
				page: {
					classList: ["measuringPeriodEnd"],
				},
				model,
			}),
		},

		{
			name: "MeasuringPeriodDetail",
			path: `${Config.routes.bins.measuringPeriodDetail}:id`,
			component: MeasuringPeriodDetail,
			meta: {
				title: "Meetperiode Detail",
			},
			props: ({ params: backUrl }) => ({
				page: {
					classList: ["measuringPeriodDetail"],
				},
				backUrl,
			}),
		},
        {
            name: 'EventOverview',
            path: `${Config.routes.volunteers.eventsOverview}`,
            component: EventOverview,
            meta: {
                title: 'Opruimacties',
            },
            props: {
                page: {
                    classList: ['EventsOverview'],
                },
            },
        },
        {
            name: 'EventDetail',
            path: `${Config.routes.volunteers.eventsDetail}:id`,
            component: EventDetail,
            meta: {
                title: 'Opruimactie Detail',
            },
            props: ({ params: backUrl }) => ({
                page: {
                    classList: ['EventDetail'],
                },
                backUrl
            }),
        },
        {
            name: 'GroupsOverview',
            path: `${Config.routes.volunteers.groupsOverview}`,
            component: GroupsOverview,
            meta: {
                title: 'Groepen',
            },
            props: {
                page: {
                    classList: ['groupsOverview'],
                },
            },
        },
        {
            name: 'GroupsDetail',
            path: `${Config.routes.volunteers.groupsDetail}:id`,
            component: GroupsDetail,
            meta: {
                title: 'Groepen Detail',
            },
            props: ({ params: backUrl }) => ({
                page: {
                    classList: ['groupsDetail'],
                },
                backUrl
            }),
        },
        {
            name: 'VolunteerPostWizard',
            path: Config.routes.volunteers.postWizard,
            component: VolunteerPostWizard,
            meta: {
                title: 'Post aanmaken - Mijn Mooie Straat',
            },
            props: ({ params: { group, backUrl } }) => ({
                page: {
                    classList: ['volunteerPostWizard'],
                },
                group,
                backUrl,
            }),
        },
        {
            name: 'VolunteerPostDetail',
            path: `${Config.routes.volunteers.postDetail}:id`,
            component: VolunteerPostDetail,
            meta: {
                title: 'Post Detail',
            },
            props: {
                page: {
                    classList: ['volunteerPostDetail'],
                },
            },
        },
	],
};
